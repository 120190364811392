<template>
  <div class="adata">
    <!-- <el-row :gutter="0" class="eqm_number">
      <el-col :span="3" :offset="9" class="in_number">
        <span>设备总数:</span><span>{{ total_devices }}</span>
      </el-col>
      <el-col :span="3" class="in_number">
        <span>在线数量:</span><span>{{ online_devices }}</span>
      </el-col>
    </el-row> -->
    <el-row :gutter="1" type="flex" justify="space-between" class="chart">
      <el-col class="chart_item" :span="7"
        ><div id="histogram"></div>
        <div id="extension" class="extension"></div
      ></el-col>
      <el-col class="chart_item" :span="8">
        <div id="piechart"></div>
        <div class="piechart_item">
          <div>
            设备总数：<span>{{ total_devices }}</span>
          </div>
          <div>
            在线总数：<span>{{ online_devices }}</span>
          </div>
        </div>
      </el-col>
      <el-col class="chart_item" :span="7"><div id="linechart"></div></el-col>
    </el-row>

    <el-row :gutter="0" class="search" align="middle" type="flex">
      <el-col :span="2" style="text-align: center">设备编号:</el-col>
      <el-col :span="3" style="text-align: center">
        <el-select
          v-if="userRoleId === '0'"
          v-model="id"
          clearable
          placeholder="请选择设备"
          @change="userselecteqm($event)"
        >
          <el-option
            v-for="item in useroptions"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-cascader
          v-if="userRoleId === '1' || userRoleId === '2'"
          :options="options"
          @change="handleChange($event)"
          :props="optionProps"
          clearable
        ></el-cascader
      ></el-col>
      <el-col :span="1" style="text-align: center"><span>时间:</span></el-col>
      <el-col :span="5" style="text-align: center">
        <el-date-picker
          v-model="value2"
          value-format="yyyy-MM-dd"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="1" :offset="11" style=""
        ><el-button
          style="backgroundcolor: #0076f6"
          type="primary"
          @click="serachdata()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <el-row>
      <el-table
        :data="tableData"
        align="center"
        stripe
        style="margin-top: 10px; color: #161718"
        border
        max-height="320px"
        v-fit-columns
      >
        <el-table-column
          fixed
          v-if="userRoleId === '1' || userRoleId === '2'"
          prop="company"
          label="所属公司"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          fixed
          prop="code"
          label="设备编号"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="place" label="位置" width="180" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.place == '' || scope.row.place == null"
              >--</span
            >

            <span v-else>{{ scope.row.place }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="road_name" label="道路编号" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.road_name == '' || scope.row.road_name == null"
              >--</span
            >

            <span v-else>{{ scope.row.road_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mileage" label="桩号" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.mileage == '' || scope.row.mileage == null"
              >--</span
            >

            <span v-else>{{ scope.row.mileage }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="toward" label="去向" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.toward == '' || scope.row.toward == null"
              >--</span
            >

            <span v-else>{{ scope.row.toward }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upordown" label="施工内容" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.upordown !== ''">{{
              scope.row.upordown
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="record_date"
          label="记录日期"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="close_alarm_count"
          label="近距报警数"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.close_alarm_count }}次</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remote_alarm_count"
          label="远距报警数"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.remote_alarm_count }}次</span>
          </template>
        </el-table-column>
        <el-table-column prop="alarm_count" label="总报警数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.alarm_count }}次</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="timing_length"
          label="当天在线时长"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ changetime(scope.row.timing_length) }}</span>
          </template>
        </el-table-column>
      </el-table></el-row
    >

    <el-row class="paging" align="middle" type="flex" justify="center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev, total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  statistic,
  datapages,
  dataInfo,
  histogram,
  piechart,
  linechart,
} from "../../api/adstatisticapi";
export default {
  components: {},
  data() {
    return {
      total_devices: "",
      online_devices: "",
      selectcompanyid: null,
      useroptions: [
        {
          id: "",
          text: "",
        },
      ],
      id: "",
      /**级联选择设备 */
      options: [],
      id: null,
      optionProps: {
        checkStrictly: false,
        value: "id",
        label: "text",
        children: "children",
      },
      /**查询的开始时间 */
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      tableData: [],
      currentPage3: null,
      pagesize: null,
      total: null,
      histogram: null,
      piechart: null,
      linechart: null,
    };
  },
  created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
  mounted() {
    this.getequipmentdata();
    this.$nextTick(function () {
      this.chartsinit();
    });
  },
  methods: {
    mouseMove(ev) {
      ev = ev || window.event;
      return this.mousePosition(ev);
    },
    mousePosition(ev) {
      if (ev.pageX || ev.pageY) {
        return { x: ev.pageX, y: ev.pageY };
      }
      return {
        x: ev.clientX + document.body.scrollLeft - document.body.clientLeft,
        y: ev.clientY + document.body.scrollTop - document.body.clientTop,
      };
    },
    /**初始化图表 */
    chartsinit() {
      console.log("00000000000");
      histogram()
        .then((res) => {
          console.log(res);
          console.log("11111111111");
          if (res.status == 200) {
            if (document.getElementById("histogram") == null) {
              return;
            }
            this.$echarts.dispose(document.getElementById("histogram"));

            this.histogram = this.$echarts.init(
              document.getElementById("histogram")
            );

            let option = {
              grid: {
                left: "20%",
                bottom: "17%",
              },
              title: {
                text: "设备工作时长",
                top: "5%",
                left: "5%",
                textStyle: {
                  //文字颜色
                  color: "black",
                  //字体风格,'normal','italic','oblique'
                  fontStyle: "normal",
                  //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
                  fontWeight: "bold",
                  //字体系列
                  fontFamily: "sans-serif",
                  //字体大小
                  fontSize: "0.9em",
                },
              },
              tooltip: {},
              legend: {
                data: ["时长"],
                textStyle: {
                  fontSize: "0.75rem",
                },
                top: "5%",
                right: "0%",
                height: "auto",
              },
              xAxis: {
                triggerEvent: true,
                data: res.data.x_data,
                // axisTick: {
                //   alignWithLabel: true,
                // },
                axisLabel: {
                  // 设置为true后，可触发事件。
                  // interval: 0, //代表显示所有x轴标签显示
                  rotate: -30, //代表逆时针旋转45度
                  formatter: function (value) {
                    return value.length > 6 ? value.slice(0, 3) + "..." : value;
                  },
                  fontSize: "0.75rem",
                },
              },
              yAxis: {
                // triggerEvent: false,
                axisLabel: {
                  formatter: "{value} 小时",
                  fontSize: "0.75rem",
                },
              },
              series: [
                {
                  name: "时长",
                  type: "bar",
                  data: res.data.y_data,
                },
              ],
              axisLabel: {
                interval: 0,
              },
            };
            var that = this;
            // 使用刚指定的配置项和数据显示图表。
            this.histogram.setOption(option);
            setTimeout(function () {
              window.onresize = function () {
                myChart1.resize();
              };
            }, 200);
            // window.onresize = function () {
            //   myChart1.resize();
            // };
            this.histogram.on("mouseover", function (params) {
              console.log("鼠标移入", params);
              if (params.componentType == "xAxis") {
                //判断移入x轴
                let extension = document.getElementById("extension");
                console.log("泡", extension);
                if (params.componentType == "xAxis") {
                  let mousePos = that.mouseMove();
                  console.log("距离", mousePos);
                  //鼠标位置，按需求设置
                  let x = mousePos.x;
                  let y = mousePos.y - 20;
                  extension.innerHTML = params.value;
                  //气泡样式
                  extension.style.cssText =
                    "top:" +
                    y +
                    "px;left:" +
                    x +
                    "px;display:inline;position: fixed;";
                }
              }
            });
            document
              .getElementById("histogram")
              .addEventListener("mouseleave", function (params) {
                let extension = document.getElementById("extension");
                extension.style.cssText = "display:none;";
              });
          } else {
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
      piechart()
        .then((res) => {
          console.log(res.data.data[2].total);
          console.log("饼");

          if (res.status == 200) {
            if (document.getElementById("piechart") == null) {
              return;
            }
            this.$echarts.dispose(document.getElementById("piechart"));
            this.piechart = this.$echarts.init(
              document.getElementById("piechart")
            );
            let option = {
              title: {
                text: "设备总报警",
                zlevel: 0,
                subtext: res.data.data[2].total + "次",
                textStyle: {
                  color: "#13227a",
                  fontWeight: "bold",
                  fontSize: "1em",
                },
                x: "center", //文字位置
                y: "center", //文字位置
              },

              legend: {
                show: false,
              },
              tooltip: {
                show: false,
              },

              legend: {
                show: false,
                selectedMode: false,
              },
              series: [
                {
                  name: "访问来源",
                  type: "pie",
                  radius: ["60%", "45%"],
                  avoidLabelOverlap: false,
                  hoverAnimation: false,
                  label: {
                    show: true,
                    position: "outer",
                    formatter: "{b}\n{c}次",
                    rich: {
                      c: {
                        color: "#13227a",
                        fontSize: "12px",
                        lineHeight: "10px",
                      },
                      b: {
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "#333",
                      },
                    },
                    textStyle: {
                      fontSize: "1rem",
                      color: "#666",
                    },
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: "15px",
                      fontWeight: "bold",
                    },
                  },
                  labelLine: {
                    show: true,
                    length: 12,
                    length2: 13,
                    lineStyle: {
                      color: "#808080",
                    },
                  },
                  data: res.data.data,
                },
              ],
            };

            this.piechart.setOption(option);
            window.onresize = function () {
              myChart2.resize();
            };
          } else {
          }
        })
        .catch((e) => {});
      linechart()
        .then((res) => {
          console.log(res);
          console.log("折线");
          if (res.status == 200) {
            if (document.getElementById("linechart") == null) {
              return;
            }
            this.$echarts.dispose(document.getElementById("linechart"));
            this.linechart = this.$echarts.init(
              document.getElementById("linechart")
            );
            let option = {
              grid: {
                left: "20%",
                bottom: "18%",
              },
              title: {
                text: "远近距报警",
                top: "5%",
                left: "5%",
                textStyle: {
                  //文字颜色
                  color: "black",
                  //字体风格,'normal','italic','oblique'
                  fontStyle: "normal",
                  //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
                  fontWeight: "bold",
                  //字体系列
                  fontFamily: "sans-serif",
                  //字体大小
                  fontSize: "0.9em",
                },
              },
              tooltip: {
                trigger: "axis",
              },
              legend: {
                data: ["近距报警", "远距报警"],
                textStyle: {
                  fontSize: "0.75rem",
                },

                top: "5%",
                right: "0%",
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: res.data.x_data,
                axisLabel: {
                  formatter: "{value}:00",
                  interval: 0,
                  fontSize: "0.75rem",
                  rotate: -45, //代表逆时针旋转45度
                },
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  formatter: "{value} 次",
                  fontSize: "0.75rem",
                },
              },
              series: [
                {
                  data: res.data.close_alarm_count,
                  type: "line",
                  name: "近距报警",
                  fontSize: "0.75rem",
                  areaStyle: {
                    fontSize: "0.75rem",
                  },
                },
                {
                  data: res.data.remote_alarm_count,
                  type: "line",
                  name: "远距报警",

                  areaStyle: {
                    fontSize: "0.75rem",
                  },
                },
              ],
            };
            // 使用刚指定的配置项和数据显示图表。
            this.linechart.setOption(option);
            let that = this;
            window.onresize = function () {
              that.linechart.resize();
              that.piechart.resize();
              that.histogram.resize();
            };
          } else {
          }
        })
        .catch((e) => {});
    },
    /**获取数据表格 */
    getequipmentdata() {
      statistic()
        .then((res) => {
          console.log(res);
          console.log("这里分页");
          this.total_devices = res.data.total_devices;
          this.online_devices = res.data.online_devices;
          this.useroptions = res.data.casecade[0].children;
          this.selectcompanyid = res.data.casecade[0].id;
          this.options = res.data.casecade;
          this.tableData = res.data.datas;
          this.pagesize = res.data.page_size;
          this.total = res.data.total_pages;
        })
        .catch((err) => {});
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(this.value2, this.id, val);
      if (this.value2) {
        dataInfo(this.id, this.value2[0], this.value2[1], val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        dataInfo(this.id, "", "", val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
      // datapages(val)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       console.log(res);
      //       console.log("啥啥啥");
      //       this.tableData = res.data.datas;
      //     }
      //   })
      //   .catch(() => {
      //     this.$message.error(e.message);
      //   });
      // console.log(`当前页: ${val}`);
    },
    /**级联选择设备 */
    userselecteqm(e) {
      console.log(e, "用户用户");
      this.eqmid = e;
    },
    handleChange(e) {
      console.log(e[1]);
      this.id = e[1];
      dataInfo(e[1], this.startvalue2, this.endvalue2)
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            this.tableData = res.data.datas;
            this.pagesize = res.data.page_size;
            this.total = res.data.total_pages;
          }
        })
        .catch(() => {
          this.$message.error(e.message);
        });
    },

    serachdata() {
      console.log(this.value2);

      console.log("这是时间");
      this.currentPage3 = 1;
      if (this.value2) {
        dataInfo(this.id, this.value2[0], this.value2[1])
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        dataInfo(this.id, "", "")
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
    changetime(str) {
      if (str) {
        return str < 60
          ? str.toString() + "分钟"
          : str % 60 === 0
          ? (str / 60).toString() + "小时"
          : Math.floor(str / 60).toString() +
            "小时" +
            (str % 60).toString() +
            "分钟";
      } else {
        return "暂无数据";
      }
    },
  },
};
</script>
<style scoped>
.adata {
  /* background-color: blueviolet; */
  padding: 20px 30px 0 30px;
}
.extension {
  display: none;
  padding: 2px 5px;
  border-radius: 5px;
  background: white;
}
.chart {
  /* background-color: cornflowerblue; */
}
.chart_item {
  background-color: #ebeff5;
}
/* .alldata {
  background-color: white;
} */
.el-row {
  /* margin-bottom: 20px; */
}
.el-col {
  border-radius: 10px;
}
#countdata {
  height: 400px;
  /* background-color: violet; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}
#countdata li {
  background-color: yellowgreen;
  border: 1px solid black;
  font-size: 30px;
}
#histogram {
  height: 340px;
  /* background-color: cyan; */
}
#piechart {
  height: 340px;

  /* background-color: brown; */
}
.chart_item {
  position: relative;
}
.piechart_item {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}
#linechart {
  height: 340px;
  /* background-color: cornflowerblue; */
}
.search {
  background-color: #ebeff5;
  border-radius: 10px;
  margin-top: 20px;

  padding: 8px 10px 8px 10px;
  color: #161718;
}
.el-divider {
  margin: 0;
}
.paging {
  width: 70%;
  float: right;
  /* background-color: chocolate; */
  text-align: end;
  margin-top: 20px;
}
.eqm_number {
  /* background-color: brown; */
  font-size: 25px;
  font-family: "楷体";
}
.in_number {
  /* background-color: #00a2e9; */
}
@media all and (min-width: 1025px) and (max-width: 1920px) {
  .adata {
    /* background-color: blueviolet; */
    padding: 1.25rem 1.875rem 0 1.875rem;
  }
  .extension {
    display: none;
    padding: 0.125rem 0.3125rem;
    border-radius: 0.3125rem;
    background: white;
  }
  .chart {
    /* background-color: cornflowerblue; */
  }
  .chart_item {
    background-color: #ebeff5;
  }
  /* .alldata {
  background-color: white;
} */
  .el-row {
    /* margin-bottom: 1.25rem; */
  }
  .el-col {
    border-radius: 0.625rem;
  }
  #countdata {
    height: 25rem;
    /* background-color: violet; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
  }
  #countdata li {
    background-color: yellowgreen;
    border: 0.0625rem solid black;
    font-size: 1.875rem;
  }
  #histogram {
    height: 21.25rem;
    /* background-color: cyan; */
  }
  #piechart {
    height: 21.25rem;

    /* background-color: brown; */
  }
  .chart_item {
    position: relative;
  }
  .piechart_item {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
  }
  #linechart {
    height: 21.25rem;
    /* background-color: cornflowerblue; */
  }
  .search {
    background-color: #ebeff5;
    border-radius: 0.625rem;
    margin-top: 1.25rem;

    padding: 0.5rem 0.625rem 0.5rem 0.625rem;
    color: #161718;
  }
  .el-divider {
    margin: 0;
  }
  .paging {
    width: 34%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
  .eqm_number {
    /* background-color: brown; */
    font-size: 1.5625rem;
    font-family: "楷体";
  }
  .in_number {
    /* background-color: #00a2e9; */
  }
}
@media only screen and (max-width: 1080px) {
  .adata {
    padding: 0.625rem 0.625rem 0 0.625rem;
  }
  .extension {
    display: none;
    padding: 0.125rem 0.3125rem;
    border-radius: 0.3125rem;
    background: white;
  }
  .chart {
    margin: 0 !important;
    /* background-color: cornflowerblue; */
  }
  .chart_item {
    background-color: #ebeff5;
  }

  .el-row {
    /* margin-bottom: 1.25rem; */
  }
  .el-col {
    border-radius: 0.625rem;
  }
  #countdata {
    /* height: 25rem; */
    /* background-color: violet; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
  }
  #countdata li {
    /* background-color: yellowgreen;
  border: 0.0625rem solid black; */
    font-size: 1.875rem;
  }
  #histogram {
    height: 15rem;
    /* background-color: cyan; */
    overflow: auto;
  }
  #piechart {
    height: 15rem;

    /* background-color: brown; */
  }
  .chart_item {
    position: relative;
  }
  .piechart_item {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.9375rem;
    font-weight: bold;
  }
  #linechart {
    height: 15rem;

    /* background-color: cornflowerblue; */
  }
  .search {
    background-color: #ebeff5;
    border-radius: 0.625rem;
    margin-top: 0.625rem;
    color: #161718;
  }
  .el-divider {
    margin: 0;
  }
  .paging {
  }
  .eqm_number {
    /* background-color: brown; */
    font-size: 1.5625rem;
    font-family: "楷体";
  }
  .el-table {
  }
}
</style> 