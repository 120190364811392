import service from "../util/link"
import url from "../util/apiurl"

/*数据统计请求 */
export function statistic() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.statistic,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function datapages(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.statistic,
            method: "get",
            params: {
                page: obj
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function dataInfo(codeid, startTime, endTime,page) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.statistic,
            method: "get",
            params: {
                codeid: codeid,
                startTime: startTime,
                endTime: endTime,
                page: (typeof (page) == 'number') || (typeof (page) == 'string') ? page : "",
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function histogram() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.histogram,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function piechart() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.piechart,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function linechart() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.linechart,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}